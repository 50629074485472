import { useCartStore } from "~/stores/cart";
import { usePromoSideModalStore } from "~/stores/promoSideModal";

export default defineNuxtPlugin(async (nuxtApp) => {
    if (process.client) {
        const cartStore = useCartStore();
        const promoSideModalStore = usePromoSideModalStore();

        let couponCookie = useCookie('coupon', { readonly: true });
        // cartStore.promocode = couponCookie.value;

        // console.log({ promo: cartStore.promocode });

        cartStore.fetch(couponCookie.value);
        promoSideModalStore.fetchPromo();
    }
});