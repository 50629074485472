import { defineStore } from 'pinia';
export const usePromoSideModalStore = defineStore('promoSideModal', {

    state: () => ({
        promoData: null as any,
        opened: false
    }),
    getters: {
        showModal(state) {
            return state.opened && state.promoData;
        }
    },

    actions: {
        async fetchPromo() {
            try {
                const res = await useDFetch('modals/side', {
                    tempGuest: true,
                    headers: {
                        'app-version': useRuntimeConfig().public.appVersion,
                    },
                });
                this.promoData = res;

                console.log({ res });

                if (this.promoData) {
                    this.show();
                }
            } catch (error) {
                this.promoData = null;
            }
        },

        show() {
            try {
                if (this.promoData) {
                    const lastShownDate = localStorage.getItem('side-modal.' + this.promoData.key);
                    if (lastShownDate) {
                        const { $dayjs } = useNuxtApp();

                        const oneDayAgo = $dayjs().subtract(1, 'day');
                        const storedDate = $dayjs(lastShownDate);
                        this.opened = oneDayAgo.isAfter(storedDate);
                    } else {
                        this.opened = true;
                    }
                } else {
                    this.opened = false;
                }
            } catch (error) {
                this.opened = false;
            }

        }
    },
});